<template>
    <!-- 管理员- 周报管理 -->
    <div class="weekly wrapper">
        <proTitle></proTitle>
        <el-form :inline="true" class="demo-form-inline flex-align-between">
            <div class="formInline-left">
            </div>
            <div class="formInline-right flex-align-between">
                <div class="form-inline-item csp">
                    <i class="el-icon-circle-plus-outline"></i>
                    <span class="csp" @click="showCreateDialog = true;">上传周报</span>
                </div>
            </div>
        </el-form>
        <div class="table_section">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="number" label="序号" width="80" align="center" type="index" :index="indexMethod">
                </el-table-column>
                <el-table-column prop="name" label="周报名称" align="center">
                </el-table-column>
                <el-table-column prop="createTime" label="上传时间" align="center">
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button @click="editFile(scope.row)" type="text" size="small">编辑
                        </el-button>
                        <el-button type="text" size="small" @click="deleteProjectFile(scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination :total="pages.total" :page.sync="pages.currentPage" :limit.sync="pages.pageSize"
                :pageSizes="[10,20,30]" @pagination="handlePageChange">
            </pagination>
        </div>
        <!-- :before-close="closeDialog" -->
        <el-dialog :title="dialogTitle" :visible.sync="showCreateDialog" width="600px" @close="closeDialog"
            destroy-on-close>
            <el-form ref="createForm" :model="createForm" label-width="120px" :rules="rules">
                <el-form-item label="文件名称：" prop="name">
                    <el-input v-model.trim="createForm.name" size="mini" style="width:220px;" placeholder="请输入标题"
                        maxlength="50" show-word-limit></el-input>
                </el-form-item>

                <el-form-item label="上传附件：" prop="identifier">
                    <upload @uploadFile="handleFileChange" :name="'identifier'" :list="editIdentifierList"
                        :text="'上传周报'" />
                </el-form-item>

                <el-form-item class="btns-box" label-width="0px">
                    <el-button class="submit btn" @click="onSubmit">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>

</template>

<script>
    import upload from './components/upload-file.vue';
    import {
        cloneDeep
    } from 'lodash/lang'

    export default {
        components: {
            upload
        },
        data() {
            return {
                activeName: "",
                rules: {
                    name: {
                        required: true,
                        message: "请输入文件名称",
                        trigger: "blur"
                    },
                },
                tableData: [],
                pages: { //分页
                    currentPage: 1,
                    pageSize: 10,
                    total: 0,
                },
                showCreateDialog: false,
                createForm: {
                    name: undefined,
                    identifier: undefined,
                    type: 4,
                },
                dialogTitle: '上传周报',
                editIdentifierList: [],
            };
        },
        methods: {
            indexMethod(index) {
                return this.pages.currentPage > 1 ?
                    (this.pages.pageSize * (this.pages.currentPage - 1)) + index + 1 :
                    index + 1
            },
            // 上传文件
            handleFileChange(data) {
                // console.log(data);
                this.createForm[data.name] = data.fileMD5List[0];
                Vue.delete(this.createForm, 'fileName');
                if (JSON.stringify(data.fileMD5List) != '[]') {
                    Vue.set(this.createForm, 'fileName', data.fileList[0].name);
                }
            },
            // 翻页
            handlePageChange(data) {
                this.pages.currentPage = data.pageIndex
                this.pages.pageSize = data.pageSize
                this.getWeeklyList();
            },
            // 获取周报列表
            async getWeeklyList() {
                let projectId = sessionStorage.getItem('projectId');
                let pramas = {
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                    projectId:projectId?projectId:this.$store.state.user.currentProject.id,
                    type: 4,
                }
                let resData = await this.$Api.Project.getProjectFileList(pramas);
                // console.log(resData);
                this.tableData = resData.data.records
                this.pages.total = resData.data.total
            },
            // 关闭上传弹窗
            closeDialog() {
                this.editIdentifierList = [];
                this.dialogTitle = '上传周报'
                Vue.set(this.createForm, 'name', undefined);
                Vue.set(this.createForm, 'identifier', undefined);
                Vue.delete(this.createForm, 'id');
                Vue.delete(this.createForm, 'createTime');
                Vue.delete(this.createForm, 'fileName');
            },
            // 编辑
            editFile(item) {
                this.createForm = cloneDeep(item);
                this.dialogTitle = `编辑${item.name}`
                this.editIdentifierList.push({
                    identifier: item.identifier,
                    filename: item.fileName
                });
                this.showCreateDialog = true;
            },
            onSubmit() {
                this.$refs['createForm'].validate((valid) => {
                    // console.log('valid',valid);
                    if (valid) {
                        this.submitForm();
                    } else {
                        console.log('error')
                    }
                })
            },
            // 表单提交
            async submitForm() {
                let msg = undefined;
                let type = undefined;
                // 新建周报
                if (this.dialogTitle == '上传周报') {
                    let resData = await this.$Api.Project.addProjectFile(this.createForm);
                    // console.log('add',resData);
                    msg = resData.code == 200 ? '上传成功' : '上传失败';
                    type = resData.code == 200 ? 'success' : 'error';
                }
                // 编辑周报
                if (this.dialogTitle != '上传周报') {
                    let resData = await this.$Api.Project.editProjectFile(this.createForm);
                    // console.log('edit',resData);
                    msg = resData.data.code == 200 ? '编辑成功' : '编辑失败';
                    type = resData.data.code == 200 ? 'success' : 'error';
                }
                this.$message({
                    message: msg,
                    type: type
                });
                this.getWeeklyList();
                this.showCreateDialog = false;
                return;
            },
            // 删除
            deleteProjectFile(item) {
                this.$confirm(`是否删除${item.name}？`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .then(() => {
                        let params = {
                            id: item.id
                        }
                        console.log(params);
                        this.$Api.Project.deleteProjectFile(params).then((resData) => {
                            console.log(resData);
                            if (resData.code == 200) {
                                this.$message({
                                    message: "删除成功",
                                    type: "success"
                                });
                                this.getWeeklyList();
                            } else {
                                this.$message.error(resData.msg);
                            }
                        }).catch((err) => {
                            console.log(err);
                        })
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            },
        },
        mounted() {
            this.getWeeklyList();
            Vue.set(this.createForm, 'projectId', this.$store.state.user.currentProject.id);
        },
    }
</script>

<style lang="less" scoped>
    @import "../manage/less/table.less";

    .weekly {
        padding: 20px 25px;
        box-sizing: border-box;

        /deep/ .el-dialog {
            border-radius: 10px;

            .el-dialog__header {
                border-bottom: 1px solid #E4E4E4;
                padding: 15px 31px 10px;
            }

            .el-dialog__title {
                font-size: 15px;
                color: #333;

                &::before {
                    content: '';
                    width: 5px;
                    height: 17px;
                    background-color: #01A2FD;
                    display: inline-block;
                    margin-right: 14px;
                    vertical-align: -3px;
                    border-radius: 3px;
                }
            }

            .el-dialog__headerbtn {
                top: 15px;
            }

            .btns-box {
                text-align: center;
            }

            .submit {
                background: linear-gradient(180deg, #5DA7FF 0%, #4893F9 100%);
                border-radius: 25px;
                color: #fff;
                line-height: 35px;
                padding: 0 63px;
                cursor: pointer;
                border: 0;

                &:hover {
                    background: linear-gradient(180deg, rgba(93, 167, 255, .8) 0%, rgba(72, 147, 249, .8) 100%);
                }
            }
        }

        .table_section {
            margin-top: 15px;
        }
    }
</style>